export const projects = [
    {
        name: 'Helioth',
        img: 'helioth.png'
    },
    {
        name: 'Cities Destinations',
        img: 'cities.png'
    },
    {
        name: 'Portfolio 2',
        img: 'portfolio2.png'
    },
    {
        name: 'Galaxy Generator',
        img: 'galaxy.png'
    },
    {
        name: 'JSP',
        img: 'jsp.png'
    },
    {
        name: 'Astronaut',
        img: 'astronaut.png'
    },
]